import React, { useCallback } from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import * as SurveyTheme from "survey-core/themes";
import "survey-core/i18n/russian";
import {
  telegramSendMessage
} from 'telegram-message-send';
import { json } from "../data/survey_json.js";
import {useTelegram} from "../hooks/useTelegram";
const botKey = '7196857573:AAG4F4Tqw2R2Xn1CfBaQhqI6hVGEtq_qXpg';
const chatId = '220178914';
const chatId2 = '6612427535';


function onValueChanged(_, options) {
  console.log("New value: " + options.value);
}

function onComplete(survey) {
  const message_full = "%0A%0AВ каком домике отдыхали: " + JSON.stringify(survey.data.domik) +
  "%0A%0AВсе ли работало в домике?: " + JSON.stringify(survey.data.is_working) +
  "%0A%0AПозитив: " + JSON.stringify(survey.data.Positive) +
  "%0A%0AНегатив или предложения что добавить: " + JSON.stringify(survey.data.Negative) +
  "%0A%0AРаботает: " + JSON.stringify(survey.data.WorkingOrNot) +
  "%0A%0AКак узнали о нас: " + JSON.stringify(survey.data.howKnow) +
  "%0A%0Aдругое: " + JSON.stringify(survey.data.Drugoe) +
  "%0A%0AВпервый раз: " + JSON.stringify(survey.data.FirstTimer) +
  "%0A%0AПочему в этом домике: " + JSON.stringify(survey.data.WhyThisDomik) +
  "%0A%0AПорекомендуют: " + JSON.stringify(survey.data.Recommend) +
  "%0A%0AСколько лет: " + JSON.stringify(survey.data.howOld);
  const message_email = "%0A%0Aemail для обратной связи: " + JSON.stringify(survey.data.nemail);
  
  telegramSendMessage(botKey, chatId, message_full);
  telegramSendMessage(botKey, chatId2, message_full);
  telegramSendMessage(botKey, chatId, message_email);
  
  const {tg} = useTelegram();
  console.log("Survey complete! Results: " + JSON.stringify(survey.data));
  tg.sendData(JSON.stringify(survey.data));
}

export function SurveyPage() {
  const model = new Model(json);
  model.applyTheme(SurveyTheme.FlatLight);
  model.locale = 'ru';
  return (
    <div className="container">
      <Survey
        model={model}
        onComplete={onComplete}
        onValueChanged={onValueChanged}
      />
    </div>
  );
}
